// ACCESS TO PAGE CONSTANTS
export const ADD_ACCESS_TO_PAGE_REQUEST = 'ADD_ACCESS_TO_PAGE_REQUEST';

export const VISIT_HOME_PAGE = 'VISIT_HOME_PAGE';
export const VISIT_CURRENT_USER_PROFILE = 'VISIT_CURRENT_USER_PROFILE';

export const VISIT_NAL_LOGIN_PAGE = 'VISIT_NAL_LOGIN_PAGE';

export const VISIT_BOSH_REDIRECT_PAGE = 'VISIT_BOSH_REDIRECT_PAGE';

export const VISIT_BOSH_CALLBACK_PAGE = 'VISIT_BOSH_CALLBACK_PAGE';

export const VISIT_ROLES_LIST_PAGE = 'VISIT_ROLES_LIST_PAGE';
export const CHECK_VISIT_ROLES_LIST_PAGE = 'CHECK_VISIT_ROLES_LIST_PAGE';
export const CHECK_VISIT_ROLES_LIST_PAGE_ALLOWED = 'CHECK_VISIT_ROLES_LIST_PAGE_ALLOWED';

export const VISIT_ROLE_EDIT_PAGE = 'VISIT_ROLE_EDIT_PAGE';
export const CHECK_VISIT_ROLE_EDIT_PAGE = 'CHECK_VISIT_ROLE_EDIT_PAGE';
export const CHECK_VISIT_ROLE_EDIT_PAGE_ALLOWED = 'CHECK_VISIT_ROLE_EDIT_PAGE_ALLOWED';

export const VISIT_ROLE_CREATE_PAGE = 'VISIT_ROLE_CREATE_PAGE';
export const CHECK_VISIT_ROLE_CREATE_PAGE = 'CHECK_VISIT_ROLE_CREATE_PAGE';
export const CHECK_VISIT_ROLE_CREATE_PAGE_ALLOWED = 'CHECK_VISIT_ROLE_CREATE_PAGE_ALLOWED';

export const VISIT_USERS_LIST_PAGE = 'VISIT_USERS_LIST_PAGE';
export const CHECK_VISIT_USERS_LIST_PAGE = 'CHECK_VISIT_USERS_LIST_PAGE';
export const CHECK_VISIT_USERS_LIST_PAGE_ALLOWED = 'CHECK_VISIT_USERS_LIST_PAGE_ALLOWED';

export const VISIT_USER_EDIT_PAGE = 'VISIT_USER_EDIT_PAGE';
export const CHECK_VISIT_USER_EDIT_PAGE = 'CHECK_VISIT_USER_EDIT_PAGE';
export const CHECK_VISIT_USER_PROFILE_PAGE = 'CHECK_VISIT_USER_PROFILE_PAGE';
export const CHECK_VISIT_USER_EDIT_PAGE_ALLOWED = 'CHECK_VISIT_USER_EDIT_PAGE_ALLOWED';
export const CHECK_VISIT_USER_PROFILE_PAGE_ALLOWED = 'CHECK_VISIT_USER_PROFILE_PAGE_ALLOWED';

export const VISIT_USER_CREATE_PAGE = 'VISIT_USER_CREATE_PAGE';
export const CHECK_VISIT_USER_CREATE_PAGE = 'CHECK_VISIT_USER_CREATE_PAGE';
export const CHECK_VISIT_USER_CREATE_PAGE_ALLOWED = 'CHECK_VISIT_USER_CREATE_PAGE_ALLOWED';

// ITEMS
export const VISIT_ITEMS_LIST_PAGE = 'VISIT_ITEMS_LIST_PAGE';
export const CHECK_VISIT_ITEMS_LIST_PAGE = 'CHECK_VISIT_ITEMS_LIST_PAGE';
export const CHECK_VISIT_ITEMS_LIST_PAGE_ALLOWED = 'CHECK_VISIT_ITEMS_LIST_PAGE_ALLOWED';

export const VISIT_SEQUENCES_LIST_PAGE = 'VISIT_SEQUENCES_LIST_PAGE';
export const CHECK_VISIT_SEQUENCES_LIST_PAGE = 'CHECK_VISIT_SEQUENCES_LIST_PAGE';
export const CHECK_VISIT_SEQUENCES_LIST_PAGE_ALLOWED = 'CHECK_VISIT_SEQUENCES_LIST_PAGE_ALLOWED';

export const VISIT_SEQUENCE_EDIT_PAGE = 'VISIT_SEQUENCE_EDIT_PAGE';
export const CHECK_VISIT_SEQUENCE_EDIT_PAGE = 'CHECK_VISIT_SEQUENCE_EDIT_PAGE';
export const CHECK_VISIT_SEQUENCE_EDIT_PAGE_ALLOWED = 'CHECK_VISIT_SEQUENCE_EDIT_PAGE_ALLOWED';

export const VISIT_SEQUENCE_CREATE_PAGE = 'VISIT_SEQUENCE_CREATE_PAGE';
export const CHECK_VISIT_SEQUENCE_CREATE_PAGE = 'CHECK_VISIT_SEQUENCE_CREATE_PAGE';
export const CHECK_VISIT_SEQUENCE_CREATE_PAGE_ALLOWED =
  'CHECK_VISIT_SEQUENCE_CREATE_PAGE_ALLOWED';

export const VISIT_SHIP_UNITS_LIST_PAGE = 'VISIT_SHIP_UNITS_LIST_PAGE';
export const CHECK_VISIT_SHIP_UNITS_LIST_PAGE = 'CHECK_VISIT_SHIP_UNITS_LIST_PAGE';
export const CHECK_VISIT_SHIP_UNITS_LIST_PAGE_ALLOWED = 'CHECK_VISIT_SHIP_UNITS_LIST_PAGE_ALLOWED';

export const VISIT_SHIP_UNIT_EDIT_PAGE = 'VISIT_SHIP_UNIT_EDIT_PAGE';
export const CHECK_VISIT_SHIP_UNIT_EDIT_PAGE = 'CHECK_VISIT_SHIP_UNIT_EDIT_PAGE';
export const CHECK_VISIT_SHIP_UNIT_EDIT_PAGE_ALLOWED = 'CHECK_VISIT_SHIP_UNIT_EDIT_PAGE_ALLOWED';

export const VISIT_SHIP_UNIT_CREATE_PAGE = 'VISIT_SHIP_UNIT_CREATE_PAGE';
export const CHECK_VISIT_SHIP_UNIT_CREATE_PAGE = 'CHECK_VISIT_SHIP_UNIT_CREATE_PAGE';
export const CHECK_VISIT_SHIP_UNIT_CREATE_PAGE_ALLOWED =
  'CHECK_VISIT_SHIP_UNIT_CREATE_PAGE_ALLOWED';

export const VISIT_BRANCH_LIST_PAGE = 'VISIT_BRANCH_LIST_PAGE';
export const CHECK_VISIT_BRANCH_LIST_PAGE = 'CHECK_VISIT_BRANCH_LIST_PAGE';
export const CHECK_VISIT_BRANCH_LIST_PAGE_ALLOWED =
  'CHECK_VISIT_BRANCH_LIST_PAGE_ALLOWED';

export const VISIT_BRANCH_EDIT_PAGE = 'VISIT_BRANCH_EDIT_PAGE';
export const CHECK_VISIT_BRANCH_EDIT_PAGE = 'CHECK_VISIT_BRANCH_EDIT_PAGE';
export const CHECK_VISIT_BRANCH_EDIT_PAGE_ALLOWED = 'CHECK_VISIT_BRANCH_EDIT_PAGE_ALLOWED';

export const VISIT_BRANCH_DETAILS_PAGE = 'VISIT_BRANCH_DETAILS_PAGE';
export const CHECK_VISIT_BRANCH_DETAILS_PAGE = 'CHECK_VISIT_BRANCH_DETAILS_PAGE';
export const CHECK_VISIT_BRANCH_DETAILS_PAGE_ALLOWED =
  'CHECK_VISIT_BRANCH_DETAILS_PAGE_ALLOWED';

export const VISIT_BRANCH_CREATE_PAGE = 'VISIT_BRANCH_CREATE_PAGE';
export const CHECK_VISIT_BRANCH_CREATE_PAGE = 'CHECK_VISIT_BRANCH_CREATE_PAGE';
export const CHECK_VISIT_BRANCH_CREATE_PAGE_ALLOWED =
  'CHECK_VISIT_BRANCH_CREATE_PAGE_ALLOWED';

export const VISIT_REFERENCES_LIST_PAGE = 'VISIT_REFERENCES_LIST_PAGE';
export const CHECK_VISIT_REFERENCES_LIST_PAGE = 'CHECK_VISIT_REFERENCES_LIST_PAGE_PAGE';
export const CHECK_VISIT_REFERENCES_LIST_PAGE_ALLOWED = 'CHECK_VISIT_REFERENCES_LIST_PAGE_ALLOWED';

// TODO create in driver features
export const VISIT_FLEET_DRIVER_LIST_PAGE = 'VISIT_FLEET_DRIVER_LIST_PAGE';
export const CHECK_VISIT_FLEET_DRIVERS_LIST_PAGE = 'CHECK_VISIT_FLEET_DRIVERS_LIST_PAGE';
export const CHECK_VISIT_FLEET_DRIVERS_LIST_PAGE_ALLOWED =
  'CHECK_VISIT_FLEET_DRIVERS_LIST_PAGE_ALLOWED';

export const VISIT_FLEET_DRIVER_EDIT_PAGE = 'VISIT_FLEET_DRIVER_EDIT_PAGE';
export const CHECK_VISIT_FLEET_DRIVER_EDIT_PAGE = 'CHECK_VISIT_FLEET_DRIVER_EDIT_PAGE';
export const CHECK_VISIT_FLEET_DRIVER_EDIT_PAGE_ALLOWED =
  'CHECK_VISIT_FLEET_DRIVER_EDIT_PAGE_ALLOWED';

// fleet-profile-driver
export const VISIT_FLEET_PROFILE_DRIVER_PAGE = 'VISIT_FLEET_PROFILE_DRIVER_PAGE';
export const CHECK_VISIT_FLEET_PROFILE_DRIVER_PAGE = 'CHECK_VISIT_FLEET_PROFILE_DRIVER_PAGE';
export const CHECK_VISIT_FLEET_PROFILE_DRIVER_PAGE_ALLOWED =
  'CHECK_VISIT_FLEET_PROFILE_DRIVER_PAGE_ALLOWED';

// fleet-profile-vendor
export const VISIT_FLEET_PROFILE_VENDOR_PAGE = 'VISIT_FLEET_PROFILE_VENDOR_PAGE';
export const CHECK_VISIT_FLEET_PROFILE_VENDOR_PAGE = 'CHECK_VISIT_FLEET_PROFILE_VENDOR_PAGE';
export const CHECK_VISIT_FLEET_PROFILE_VENDOR_PAGE_ALLOWED =
  'CHECK_VISIT_FLEET_PROFILE_VENDOR_PAGE_ALLOWED';

// fleet-profile-truck
export const VISIT_FLEET_PROFILE_TRUCK_PAGE = 'VISIT_FLEET_PROFILE_TRUCK_PAGE';
export const CHECK_VISIT_FLEET_PROFILE_TRUCK_PAGE = 'CHECK_VISIT_FLEET_PROFILE_TRUCK_PAGE';
export const CHECK_VISIT_FLEET_PROFILE_TRUCK_PAGE_ALLOWED =
  'CHECK_VISIT_FLEET_PROFILE_TRUCK_PAGE_ALLOWED';

// fleet-profile-trailer
export const VISIT_FLEET_PROFILE_TRAILER_PAGE = 'VISIT_FLEET_PROFILE_TRAILER_PAGE';
export const CHECK_VISIT_FLEET_PROFILE_TRAILER_PAGE = 'CHECK_VISIT_FLEET_PROFILE_TRAILER_PAGE';
export const CHECK_VISIT_FLEET_PROFILE_TRAILER_PAGE_ALLOWED =
  'CHECK_VISIT_FLEET_PROFILE_TRAILER_PAGE_ALLOWED';

export const VISIT_FLEET_VENDOR_LIST_PAGE = 'VISIT_FLEET_VENDOR_LIST_PAGE';
export const CHECK_VISIT_FLEET_VENDOR_LIST_PAGE = 'CHECK_VISIT_FLEET_VENDOR_LIST_PAGE';
export const CHECK_VISIT_FLEET_VENDOR_LIST_PAGE_ALLOWED =
  'CHECK_VISIT_FLEET_VENDOR_LIST_PAGE_ALLOWED';

export const VISIT_FLEET_VENDOR_EDIT_PAGE = 'VISIT_FLEET_VENDOR_EDIT_PAGE';
export const CHECK_VISIT_FLEET_VENDOR_EDIT_PAGE = 'CHECK_VISIT_FLEET_VENDOR_EDIT_PAGE';
export const CHECK_VISIT_FLEET_VENDOR_EDIT_PAGE_ALLOWED =
  'CHECK_VISIT_FLEET_VENDOR_EDIT_PAGE_ALLOWED';

export const VISIT_REFERENCE_TYPES_LIST_PAGE = 'VISIT_REFERENCE_TYPES_LIST_PAGE';
export const CHECK_VISIT_REFERENCE_TYPES_LIST_PAGE = 'CHECK_VISIT_REFERENCE_TYPES_LIST_PAGE';
export const CHECK_VISIT_REFERENCE_TYPES_LIST_PAGE_ALLOWED =
  'CHECK_VISIT_REFERENCE_TYPES_LIST_PAGE_ALLOWED';

export const VISIT_LOCATIONS_LIST_PAGE = 'VISIT_LOCATIONS_LIST_PAGE';
export const CHECK_VISIT_LOCATIONS_LIST_PAGE = 'CHECK_VISIT_LOCATIONS_LIST_PAGE';
export const CHECK_VISIT_LOCATIONS_LIST_PAGE_ALLOWED =
  'CHECK_VISIT_LOCATIONS_LIST_PAGE_ALLOWED';

export const VISIT_REPORTS_LIST_PAGE = 'VISIT_REPORTS_LIST_PAGE';
export const CHECK_VISIT_REPORTS_LIST_PAGE = 'CHECK_VISIT_REPORTS_LIST_PAGE';
export const CHECK_VISIT_REPORTS_LIST_PAGE_ALLOWED =
  'CHECK_VISIT_REPORTS_LIST_PAGE_ALLOWED';

export const VISIT_CREATE_LOAD_PAGE = 'VISIT_CREATE_LOAD_PAGE';
export const CHECK_VISIT_CREATE_LOAD_PAGE = 'CHECK_VISIT_CREATE_LOAD_PAGE';
export const CHECK_VISIT_CREATE_LOAD_PAGE_ALLOWED = 'CHECK_VISIT_CREATE_LOAD_PAGE_ALLOWED';

export const VISIT_NEW_DO_PAGE = 'VISIT_NEW_DO_PAGE';
export const CHECK_VISIT_NEW_DO_PAGE = 'CHECK_VISIT_NEW_DO_PAGE';
export const CHECK_VISIT_NEW_DO_PAGE_ALLOWED = 'CHECK_VISIT_NEW_DO_PAGE_ALLOWED';
export const VISIT_NEW_DO_PAGE_BY_SOURCE_TYPE = 'VISIT_NEW_DO_PAGE_BY_SOURCE_TYPE';

export const VISIT_LITE_NEW_DO_PAGE = 'VISIT_LITE_NEW_DO_PAGE';
export const CHECK_VISIT_LITE_NEW_DO_PAGE = 'CHECK_VISIT_LITE_NEW_DO_PAGE';
export const CHECK_VISIT_LITE_NEW_DO_PAGE_ALLOWED = 'CHECK_VISIT_LITE_NEW_DO_PAGE_ALLOWED';
export const VISIT_LITE_NEW_DO_PAGE_BY_SOURCE_TYPE = 'VISIT_LITE_NEW_DO_PAGE_BY_SOURCE_TYPE';

export const VISIT_NEW_DO_QUOTE_PAGE = 'VISIT_NEW_DO_QUOTE_PAGE';
export const CHECK_VISIT_NEW_DO_QUOTE_PAGE = 'CHECK_VISIT_NEW_DO_QUOTE_PAGE';
export const CHECK_VISIT_NEW_DO_QUOTE_PAGE_ALLOWED = 'CHECK_VISIT_NEW_DO_QUOTE_PAGE_ALLOWED';

export const VISIT_CONFIG_CLO_PAGE = 'VISIT_CONFIG_CLO_PAGE';
export const CHECK_VISIT_CONFIG_CLO_PAGE = 'CHECK_VISIT_CONFIG_CLO_PAGE';
export const CHECK_VISIT_CONFIG_CLO_PAGE_ALLOWED =
  'CHECK_VISIT_CONFIG_CLO_PAGE_ALLOWED';

export const VISIT_CONFIG_TEL_PAGE = 'VISIT_CONFIG_TEL_PAGE';
export const CHECK_VISIT_CONFIG_TEL_PAGE = 'CHECK_VISIT_CONFIG_TEL_PAGE';
export const CHECK_VISIT_CONFIG_TEL_PAGE_ALLOWED =
  'CHECK_VISIT_CONFIG_TEL_PAGE_ALLOWED';

export const VISIT_CONFIG_GENERAL_PAGE = 'VISIT_CONFIG_GENERAL_PAGE';
export const CHECK_VISIT_CONFIG_GENERAL_PAGE = 'CHECK_VISIT_CONFIG_GENERAL_PAGE';
export const CHECK_VISIT_CONFIG_GENERAL_PAGE_ALLOWED =
    'CHECK_VISIT_CONFIG_GENERAL_PAGE_ALLOWED';

export const VISIT_CONFIG_OPTIONAL_PAGE = 'VISIT_CONFIG_OPTIONAL_PAGE';
export const CHECK_VISIT_CONFIG_OPTIONAL_PAGE = 'CHECK_VISIT_CONFIG_OPTIONAL_PAGE';
export const CHECK_VISIT_CONFIG_OPTIONAL_PAGE_ALLOWED =
    'CHECK_VISIT_CONFIG_OPTIONAL_PAGE_ALLOWED';

export const VISIT_CONFIG_PASSWORD_PAGE = 'VISIT_CONFIG_PASSWORD_PAGE';
export const CHECK_VISIT_CONFIG_PASSWORD_PAGE = 'CHECK_VISIT_CONFIG_PASSWORD_PAGE';
export const CHECK_VISIT_CONFIG_PASSWORD_PAGE_ALLOWED =
  'CHECK_VISIT_CONFIG_PASSWORD_PAGE_ALLOWED';

export const VISIT_CONFIG_TEMPLATES_PAGE = 'VISIT_CONFIG_TEMPLATES_PAGE';
export const CHECK_VISIT_CONFIG_TEMPLATES_PAGE = 'CHECK_VISIT_CONFIG_TEMPLATES_PAGE';
export const CHECK_VISIT_CONFIG_TEMPLATES_PAGE_ALLOWED =
  'CHECK_VISIT_CONFIG_TEMPLATES_PAGE_ALLOWED';

export const VISIT_CONFIG_DRIVER_PAGE = 'VISIT_CONFIG_DRIVER_PAGE';
export const CHECK_VISIT_CONFIG_DRIVER_PAGE = 'CHECK_VISIT_CONFIG_DRIVER_PAGE';
export const CHECK_VISIT_CONFIG_DRIVER_PAGE_ALLOWED =
  'CHECK_VISIT_CONFIG_DRIVER_PAGE_ALLOWED';

export const VISIT_FLEET_TRUCKS_LIST_PAGE = 'VISIT_FLEET_TRUCKS_LIST_PAGE';
export const CHECK_VISIT_FLEET_TRUCKS_LIST_PAGE = 'CHECK_VISIT_FLEET_TRUCKS_LIST_PAGE';
export const CHECK_VISIT_FLEET_TRUCKS_LIST_PAGE_ALLOWED =
  'CHECK_VISIT_FLEET_TRUCKS_LIST_PAGE_ALLOWED';

export const VISIT_FLEET_TRUCK_EDIT_PAGE = 'VISIT_FLEET_TRUCK_EDIT_PAGE';
export const CHECK_VISIT_FLEET_TRUCK_EDIT_PAGE = 'CHECK_VISIT_FLEET_TRUCK_EDIT_PAGE';
export const CHECK_VISIT_FLEET_TRUCK_EDIT_PAGE_ALLOWED =
  'CHECK_VISIT_FLEET_TRUCK_EDIT_PAGE_ALLOWED';

export const VISIT_FLEET_TRAILERS_LIST_PAGE = 'VISIT_FLEET_TRAILERS_LIST_PAGE';
export const CHECK_VISIT_FLEET_TRAILERS_LIST_PAGE = 'CHECK_VISIT_FLEET_TRAILERS_LIST_PAGE';
export const CHECK_VISIT_FLEET_TRAILERS_LIST_PAGE_ALLOWED =
  'CHECK_VISIT_FLEET_TRAILERS_LIST_PAGE_ALLOWED';

export const VISIT_FLEET_TRAILER_EDIT_PAGE = 'VISIT_FLEET_TRAILER_EDIT_PAGE';
export const CHECK_VISIT_FLEET_TRAILER_EDIT_PAGE = 'CHECK_VISIT_FLEET_TRAILER_EDIT_PAGE';
export const CHECK_VISIT_FLEET_TRAILER_EDIT_PAGE_ALLOWED =
  'CHECK_VISIT_FLEET_TRAILER_EDIT_PAGE_ALLOWED';

export const VISIT_CONFIG_TRUCK_PAGE = 'VISIT_CONFIG_TRUCK_PAGE';
export const CHECK_VISIT_CONFIG_TRUCK_PAGE = 'CHECK_VISIT_CONFIG_TRUCK_PAGE';
export const CHECK_VISIT_CONFIG_TRUCK_PAGE_ALLOWED =
  'CHECK_VISIT_CONFIG_TRUCK_PAGE_ALLOWED';

export const VISIT_CONFIG_TRAILER_PAGE = 'VISIT_CONFIG_TRAILER_PAGE';
export const CHECK_VISIT_CONFIG_TRAILER_PAGE = 'CHECK_VISIT_CONFIG_TRAILER_PAGE';
export const CHECK_VISIT_CONFIG_TRAILER_PAGE_ALLOWED =
  'CHECK_VISIT_CONFIG_TRAILER_PAGE_ALLOWED';

export const VISIT_CONFIG_COMMUNICATION_PAGE = 'VISIT_CONFIG_COMMUNICATION_PAGE';
export const CHECK_VISIT_CONFIG_COMMUNICATION_PAGE = 'CHECK_VISIT_CONFIG_COMMUNICATION_PAGE';
export const CHECK_VISIT_CONFIG_COMMUNICATION_PAGE_ALLOWED =
    'CHECK_VISIT_CONFIG_COMMUNICATION_PAGE_ALLOWED';

export const VISIT_CONFIG_RATING_PAGE = 'VISIT_CONFIG_RATING_PAGE';
export const CHECK_VISIT_CONFIG_RATING_PAGE = 'CHECK_VISIT_CONFIG_RATING_PAGE';
export const CHECK_VISIT_CONFIG_RATING_PAGE_ALLOWED = 'CHECK_VISIT_CONFIG_RATING_PAGE_ALLOWED';

export const VISIT_CONFIG_INVOICES_PAGE = 'VISIT_CONFIG_INVOICES_PAGE';
export const CHECK_VISIT_CONFIG_INVOICES_PAGE = 'CHECK_VISIT_CONFIG_INVOICES_PAGE';
export const CHECK_VISIT_CONFIG_INVOICES_PAGE_ALLOWED = 'CHECK_VISIT_CONFIG_INVOICES_PAGE_ALLOWED';

export const VISIT_CONFIG_UI_PAGE = 'VISIT_CONFIG_UI_PAGE';
export const CHECK_VISIT_CONFIG_UI_PAGE = 'CHECK_VISIT_CONFIG_UI_PAGE';
export const CHECK_VISIT_CONFIG_UI_PAGE_ALLOWED = 'CHECK_VISIT_CONFIG_UI_PAGE_ALLOWED';

export const VISIT_CONFIG_INTEGRATION_PAGE = 'VISIT_CONFIG_INTEGRATION_PAGE';
export const CHECK_VISIT_CONFIG_INTEGRATION_PAGE = 'CHECK_VISIT_CONFIG_INTEGRATION_PAGE';
export const CHECK_VISIT_CONFIG_INTEGRATION_PAGE_ALLOWED = 'CHECK_VISIT_CONFIG_INTEGRATION_PAGE_ALLOWED';

export const VISIT_CONFIG_MOBILEAPP_PAGE = 'VISIT_CONFIG_MOBILEAPP_PAGE';
export const CHECK_VISIT_CONFIG_MOBILEAPP_PAGE = 'CHECK_VISIT_CONFIG_MOBILEAPP_PAGE';
export const CHECK_VISIT_CONFIG_MOBILEAPP_PAGE_ALLOWED = 'CHECK_VISIT_CONFIG_MOBILEAPP_PAGE_ALLOWED';

export const VISIT_CONFIG_TASK_MANAGEMENT_PAGE = 'VISIT_CONFIG_TASK_MANAGEMENT_PAGE';
export const CHECK_VISIT_CONFIG_TASK_MANAGEMENT_PAGE = 'CHECK_VISIT_CONFIG_TASK_MANAGEMENT_PAGE';
export const CHECK_VISIT_CONFIG_TASK_MANAGEMENT_PAGE_ALLOWED = 'CHECK_VISIT_CONFIG_TASK_MANAGEMENT_PAGE_ALLOWED';

export const VISIT_CONFIG_CLAIM_MANAGEMENT_PAGE = 'VISIT_CONFIG_CLAIM_MANAGEMENT_PAGE';
export const CHECK_VISIT_CONFIG_CLAIM_MANAGEMENT_PAGE = 'CHECK_VISIT_CONFIG_CLAIM_MANAGEMENT_PAGE';
export const CHECK_VISIT_CONFIG_CLAIM_MANAGEMENT_PAGE_ALLOWED = 'CHECK_VISIT_CONFIG_CLAIM_MANAGEMENT_PAGE_ALLOWED';

// ROUTE CONSTANTS
export const VISIT_ROUTE_LIST_PAGE = 'VISIT_ROUTE_LIST_PAGE';
export const CHECK_VISIT_ROUTE_LIST_PAGE = 'CHECK_VISIT_ROUTE_LIST_PAGE';
export const CHECK_VISIT_ROUTE_LIST_PAGE_ALLOWED =
  'CHECK_VISIT_ROUTE_LIST_PAGE_ALLOWED';

export const VISIT_DISPATCH_BOARD_TEL_PAGE = 'VISIT_DISPATCH_BOARD_TEL_PAGE';
export const CHECK_VISIT_DISPATCH_BOARD_TEL_PAGE = 'CHECK_VISIT_DISPATCH_BOARD_TEL_PAGE';
export const CHECK_VISIT_DISPATCH_BOARD_TEL_PAGE_ALLOWED = 'CHECK_VISIT_DISPATCH_BOARD_TEL_PAGE_ALLOWED';
export const VISIT_DISPATCH_BOARD_CLO_PAGE = 'VISIT_DISPATCH_BOARD_CLO_PAGE';
export const CHECK_VISIT_DISPATCH_BOARD_CLO_PAGE = 'CHECK_VISIT_DISPATCH_BOARD_CLO_PAGE';
export const CHECK_VISIT_DISPATCH_BOARD_CLO_PAGE_ALLOWED = 'CHECK_VISIT_DISPATCH_BOARD_CLO_PAGE_ALLOWED';

export const VISIT_ROUTE_BUILDER_PAGE = 'VISIT_ROUTE_BUILDER_PAGE';
export const CHECK_VISIT_ROUTE_BUILDER_PAGE = 'CHECK_VISIT_ROUTE_BUILDER_PAGE';
export const CHECK_VISIT_ROUTE_BUILDER_PAGE_ALLOWED =
  'CHECK_VISIT_ROUTE_BUILDER_PAGE_ALLOWED';

export const VISIT_LOAD_PLANNER_PAGE = 'VISIT_LOAD_PLANNER_PAGE';
export const CHECK_VISIT_LOAD_PLANNER_PAGE = 'CHECK_VISIT_LOAD_PLANNER_PAGE';
export const CHECK_VISIT_LOAD_PLANNER_PAGE_ALLOWED =
  'CHECK_VISIT_LOAD_PLANNER_PAGE_ALLOWED';

export const VISIT_CLO_PAGE = 'VISIT_CLO_PAGE';
export const CHECK_VISIT_CLO_PAGE = 'CHECK_VISIT_CLO_PAGE';
export const CHECK_VISIT_CLO_PAGE_ALLOWED = 'CHECK_VISIT_CLO_PAGE_ALLOWED';

export const VISIT_TEL_PAGE = 'VISIT_TEL_PAGE';
export const CHECK_VISIT_TEL_PAGE = 'CHECK_VISIT_TEL_PAGE';
export const CHECK_VISIT_TEL_PAGE_ALLOWED = 'CHECK_VISIT_TEL_PAGE_ALLOWED';

// CLO VIEW
export const VISIT_CLO_LIST_PAGE = 'VISIT_CLO_LIST_PAGE';
export const CHECK_VISIT_CLO_LIST_PAGE = 'CHECK_VISIT_CLO_LIST_PAGE';
export const CHECK_VISIT_CLO_LIST_PAGE_ALLOWED = 'CHECK_VISIT_CLO_LIST_PAGE_ALLOWED';

// TEL VIEW
export const VISIT_TEL_LIST_PAGE = 'VISIT_TEL_LIST_PAGE';
export const CHECK_VISIT_TEL_LIST_PAGE = 'CHECK_VISIT_TEL_LIST_PAGE';
export const CHECK_VISIT_TEL_LIST_PAGE_ALLOWED = 'CHECK_VISIT_TEL_LIST_PAGE_ALLOWED';

// ROUTE REPORT LIST
export const VISIT_ROUTE_REPORT_LIST_PAGE = 'VISIT_ROUTE_REPORT_LIST_PAGE';
export const CHECK_VISIT_ROUTE_REPORT_LIST_PAGE = 'CHECK_VISIT_ROUTE_REPORT_LIST_PAGE';
export const CHECK_VISIT_ROUTE_REPORT_LIST_PAGE_ALLOWED = 'CHECK_VISIT_ROUTE_REPORT_LIST_PAGE_ALLOWED';

// QUOTES VIEW
export const VISIT_QUOTES_LIST_PAGE = 'VISIT_QUOTES_LIST_PAGE';
export const CHECK_VISIT_QUOTES_LIST_PAGE = 'CHECK_VISIT_QUOTES_LIST_PAGE';
export const CHECK_VISIT_QUOTES_LIST_PAGE_ALLOWED = 'CHECK_VISIT_QUOTES_LIST_PAGE_ALLOWED';

// ORDER QUOTES
export const VISIT_ORDER_QUOTES_LIST_PAGE = 'VISIT_ORDER_QUOTES_LIST_PAGE';
export const CHECK_VISIT_ORDER_QUOTES_LIST_PAGE = 'CHECK_VISIT_ORDER_QUOTES_LIST_PAGE';
export const CHECK_VISIT_ORDER_QUOTES_LIST_PAGE_ALLOWED = 'CHECK_VISIT_ORDER_QUOTES_LIST_PAGE_ALLOWED';

// EDI LIST
export const VISIT_EDI_LIST_PAGE = 'VISIT_EDI_LIST_PAGE';
export const CHECK_VISIT_EDI_LIST_PAGE = 'CHECK_VISIT_EDI_LIST_PAGE';
export const CHECK_VISIT_EDI_LIST_PAGE_ALLOWED = 'CHECK_VISIT_EDI_LIST_PAGE_ALLOWED';

// CARRIER EDI LIST
export const VISIT_CARRIER_EDI_LIST_PAGE = 'VISIT_CARRIER_EDI_LIST_PAGE';
export const CHECK_VISIT_CARRIER_EDI_LIST_PAGE = 'CHECK_VISIT_CARRIER_EDI_LIST_PAGE';
export const CHECK_VISIT_CARRIER_EDI_LIST_PAGE_ALLOWED = 'CHECK_VISIT_CARRIER_EDI_LIST_PAGE_ALLOWED';

// PUBLIC CLO LIST
export const VISIT_PUBLIC_CLO_LIST_PAGE = 'VISIT_PUBLIC_CLO_LIST_PAGE';
export const CHECK_VISIT_PUBLIC_CLO_LIST_PAGE = 'CHECK_VISIT_PUBLIC_CLO_LIST_PAGE';
export const CHECK_VISIT_PUBLIC_CLO_LIST_PAGE_ALLOWED = 'CHECK_VISIT_PUBLIC_CLO_LIST_PAGE_ALLOWED';

// CHARTS
export const VISIT_CHART_LIST_PAGE = 'VISIT_CHART_LIST_PAGE';
export const CHECK_VISIT_CHART_LIST_PAGE = 'CHECK_VISIT_CHART_LIST_PAGE';
export const CHECK_VISIT_CHART_LIST_PAGE_ALLOWED = 'CHECK_VISIT_CHART_LIST_PAGE_ALLOWED';

export const VISIT_CHART_CREATE_PAGE = 'VISIT_CHART_CREATE_PAGE';
export const CHECK_VISIT_CHART_CREATE_PAGE = 'CHECK_VISIT_CHART_CREATE_PAGE';
export const CHECK_VISIT_CHART_CREATE_PAGE_ALLOWED = 'CHECK_VISIT_CHART_CREATE_PAGE_ALLOWED';

export const VISIT_CHART_EDIT_PAGE = 'VISIT_CHART_EDIT_PAGE';
export const CHECK_VISIT_CHART_EDIT_PAGE = 'CHECK_VISIT_CHART_EDIT_PAGE';
export const CHECK_VISIT_CHART_EDIT_PAGE_ALLOWED = 'CHECK_VISIT_CHART_EDIT_PAGE_ALLOWED';

// ANALYTICS
export const VISIT_ANALYTICS_PAGE = 'VISIT_ANALYTICS_PAGE';
export const CHECK_VISIT_ANALYTICS_PAGE = 'CHECK_VISIT_ANALYTICS_PAGE';
export const CHECK_VISIT_ANALYTICS_PAGE_ALLOWED = 'CHECK_VISIT_ANALYTICS_PAGE_ALLOWED';

export const VISIT_ANALYTIC_MAPS_PAGE = 'VISIT_ANALYTIC_MAPS_PAGE';
export const CHECK_VISIT_ANALYTIC_MAPS_PAGE = 'CHECK_VISIT_ANALYTIC_MAPS_PAGE';
export const CHECK_VISIT_ANALYTIC_MAPS_PAGE_ALLOWED = 'CHECK_VISIT_ANALYTIC_MAPS_PAGE_ALLOWED';

export const VISIT_CONFIG_CARRIER_PAGE = 'VISIT_CONFIG_CARRIER_PAGE';
export const CHECK_VISIT_CONFIG_CARRIER_PAGE = 'CHECK_VISIT_CONFIG_CARRIER_PAGE';
export const CHECK_VISIT_CONFIG_CARRIER_PAGE_ALLOWED = 'CHECK_VISIT_CONFIG_CARRIER_PAGE_ALLOWED';

export const VISIT_DASHBOARDS_PAYROLL_PAGE = 'VISIT_DASHBOARDS_PAYROLL_PAGE';
export const CHECK_VISIT_DASHBOARDS_PAYROLL_PAGE = 'CHECK_VISIT_DASHBOARDS_PAYROLL_PAGE';
export const CHECK_VISIT_DASHBOARDS_PAYROLL_PAGE_ALLOWED = 'CHECK_VISIT_DASHBOARDS_PAYROLL_PAGE_ALLOWED';

// DASHBOARD
export const VISIT_DASHBOARDS_PAGE = 'VISIT_DASHBOARDS_PAGE';
export const CHECK_VISIT_DASHBOARDS_PAGE = 'CHECK_VISIT_DASHBOARDS_PAGE';
export const CHECK_VISIT_DASHBOARDS_PAGE_ALLOWED = 'CHECK_VISIT_DASHBOARDS_PAGE_ALLOWED';

// CARRIER
export const VISIT_CARRIER_LIST_PAGE = 'VISIT_CARRIER_LIST_PAGE';
export const CHECK_VISIT_CARRIER_LIST_PAGE = 'CHECK_VISIT_CARRIER_LIST_PAGE';
export const CHECK_VISIT_CARRIER_LIST_PAGE_ALLOWED = 'CHECK_VISIT_CARRIER_LIST_PAGE_ALLOWED';

export const VISIT_CARRIER_EDIT_PAGE = 'VISIT_CARRIER_EDIT_PAGE';
export const CHECK_VISIT_CARRIER_EDIT_PAGE = 'CHECK_VISIT_CARRIER_EDIT_PAGE';
export const CHECK_VISIT_CARRIER_EDIT_PAGE_ALLOWED = 'CHECK_VISIT_CARRIER_EDIT_PAGE_ALLOWED';

export const VISIT_CARRIER_PROFILE_PAGE = 'VISIT_CARRIER_PROFILE_PAGE';
export const CHECK_VISIT_CARRIER_PROFILE_PAGE = 'CHECK_VISIT_CARRIER_PROFILE_PAGE';
export const CHECK_VISIT_CARRIER_PROFILE_PAGE_ALLOWED = 'CHECK_VISIT_CARRIER_PROFILE_PAGE_ALLOWED';

// INTL
export const VISIT_TRANSLATION_PAGE = 'VISIT_TRANSLATION_PAGE';
export const CHECK_VISIT_TRANSLATION_PAGE = 'CHECK_VISIT_TRANSLATION_PAGE';
export const CHECK_VISIT_TRANSLATION_PAGE_ALLOWED = 'CHECK_VISIT_TRANSLATION_PAGE_ALLOWED';

// STYLING
export const VISIT_STYLING_PAGE = 'VISIT_STYLING_PAGE';
export const CHECK_VISIT_STYLING_PAGE = 'CHECK_VISIT_STYLING_PAGE';
export const CHECK_VISIT_STYLING_PAGE_ALLOWED = 'CHECK_VISIT_STYLING_PAGE_ALLOWED';

// NOTIFICATION VIEW
export const VISIT_NOTIFICATION_LIST_PAGE = 'VISIT_NOTIFICATION_LIST_PAGE';
export const CHECK_VISIT_NOTIFICATION_LIST_PAGE = 'CHECK_VISIT_NOTIFICATION_LIST_PAGE';
export const CHECK_VISIT_NOTIFICATION_LIST_PAGE_ALLOWED = 'CHECK_VISIT_NOTIFICATION_LIST_PAGE_ALLOWED';

// INVOICE CUSTOMER
export const VISIT_INVOICE_CUSTOMER_LIST_PAGE = 'VISIT_INVOICE_CUSTOMER_LIST_PAGE';
export const CHECK_VISIT_INVOICE_CUSTOMER_LIST_PAGE = 'CHECK_VISIT_INVOICE_CUSTOMER_LIST_PAGE';
export const CHECK_VISIT_INVOICE_CUSTOMER_LIST_PAGE_ALLOWED =
  'CHECK_VISIT_INVOICE_CUSTOMER_LIST_PAGE_ALLOWED';

// INVOICE DRIVER
export const VISIT_INVOICE_DRIVER_PAYROLL_LIST_PAGE = 'VISIT_INVOICE_DRIVER_PAYROLL_LIST_PAGE';
export const CHECK_VISIT_INVOICE_DRIVER_PAYROLL_LIST_PAGE =
  'CHECK_VISIT_INVOICE_DRIVER_PAYROLL_LIST_PAGE';
export const CHECK_VISIT_INVOICE_DRIVER_PAYROLL_LIST_PAGE_ALLOWED =
  'CHECK_VISIT_INVOICE_DRIVER_PAYROLL_LIST_PAGE_ALLOWED';

// INVOICE VENDOR
export const VISIT_INVOICE_VENDOR_LIST_PAGE = 'VISIT_INVOICE_VENDOR_LIST_PAGE';
export const CHECK_VISIT_INVOICE_VENDOR_LIST_PAGE = 'CHECK_VISIT_INVOICE_VENDOR_LIST_PAGE';
export const CHECK_VISIT_INVOICE_VENDOR_LIST_PAGE_ALLOWED = 'CHECK_VISIT_INVOICE_VENDOR_LIST_PAGE_ALLOWED';

// INVOICE CARRIER
export const VISIT_INVOICE_CARRIER_LIST_PAGE = 'VISIT_INVOICE_CARRIER_LIST_PAGE';
export const CHECK_VISIT_INVOICE_CARRIER_LIST_PAGE =
  'CHECK_VISIT_INVOICE_CARRIER_LIST_PAGE';
export const CHECK_VISIT_INVOICE_CARRIER_LIST_PAGE_ALLOWED =
  'CHECK_VISIT_INVOICE_CARRIER_LIST_PAGE_ALLOWED';

// INVOICE SERVICE VENDOR
export const VISIT_INVOICE_SERVICE_VENDOR_LIST_PAGE =
  'VISIT_INVVISIT_INVOICE_SERVICE_VENDOR_LIST_PAGEOICE_CARRIER_LIST_PAGE';
export const CHECK_VISIT_INVOICE_SERVICE_VENDOR_LIST_PAGE =
  'CHECK_VISIT_INVOICE_SERVICE_VENDOR_LIST_PAGE';
export const CHECK_VISIT_INVOICE_SERVICE_VENDOR_LIST_PAGE_ALLOWED =
  'CHECK_VISIT_INVOICE_SERVICE_VENDOR_LIST_PAGE_ALLOWED';

// PAYROLL
export const VISIT_DRIVER_PAYROLL_PAGE = 'VISIT_DRIVER_PAYROLL_PAGE';
export const CHECK_VISIT_DRIVER_PAYROLL_PAGE = 'CHECK_VISIT_DRIVER_PAYROLL_PAGE';
export const CHECK_VISIT_DRIVER_PAYROLL_PAGE_ALLOWED = 'CHECK_VISIT_DRIVER_PAYROLL_PAGE_ALLOWED';

// CUSTOMER MASTER INVOICES
export const VISIT_CUSTOMER_MASTER_INVOICES_PAGE = 'VISIT_CUSTOMER_MASTER_INVOICES_PAGE';
export const CHECK_VISIT_CUSTOMER_MASTER_INVOICES_PAGE = 'CHECK_VISIT_CUSTOMER_MASTER_INVOICES_PAGE';
export const CHECK_VISIT_CUSTOMER_MASTER_INVOICES_PAGE_ALLOWED = 'CHECK_VISIT_CUSTOMER_MASTER_INVOICES_PAGE_ALLOWED';

// LOAD BOARD
export const VISIT_LOAD_BOARD_PAGE = 'VISIT_LOAD_BOARD_PAGE';
export const CHECK_VISIT_LOAD_BOARD_PAGE = 'CHECK_VISIT_LOAD_BOARD_PAGE';
export const CHECK_VISIT_LOAD_BOARD_PAGE_ALLOWED = 'CHECK_VISIT_LOAD_BOARD_PAGE_ALLOWED';

// IMPORT
export const VISIT_IMPORT_PAGE = 'VISIT_IMPORT_PAGE';
export const CHECK_VISIT_IMPORT_PAGE = 'CHECK_VISIT_IMPORT_PAGE';
export const CHECK_VISIT_IMPORT_PAGE_ALLOWED = 'CHECK_VISIT_IMPORT_PAGE_ALLOWED';

// FUEL CARDS
export const VISIT_FUEL_CARDS_PAGE = 'VISIT_FUEL_CARDS_PAGE';
export const CHECK_VISIT_FUEL_CARDS_PAGE = 'CHECK_VISIT_FUEL_CARDS_PAGE';
export const CHECK_VISIT_FUEL_CARDS_PAGE_ALLOWED = 'CHECK_VISIT_FUEL_CARDS_PAGE_ALLOWED';

// TOLL CHARGES
export const VISIT_TOLL_CHARGES_PAGE = 'VISIT_TOLL_CHARGES_PAGE';
export const CHECK_VISIT_TOLL_CHARGES_PAGE = 'CHECK_VISIT_TOLL_CHARGES_PAGE';
export const CHECK_VISIT_TOLL_CHARGES_PAGE_ALLOWED = 'CHECK_VISIT_TOLL_CHARGES_PAGE_ALLOWED';

// IFTA REPORT
export const VISIT_IFTA_REPORT_PAGE = 'VISIT_IFTA_REPORT_PAGE';
export const CHECK_VISIT_IFTA_REPORT_PAGE = 'CHECK_VISIT_IFTA_REPORT_PAGE';
export const CHECK_VISIT_IFTA_REPORT_PAGE_ALLOWED = 'CHECK_VISIT_IFTA_REPORT_PAGE_ALLOWED';

// TEMPLATES DO
export const VISIT_DO_TEMPLATE_LIST_PAGE = 'VISIT_DO_TEMPLATE_LIST_PAGE';
export const CHECK_VISIT_DO_TEMPLATE_LIST_PAGE = 'CHECK_VISIT_DO_TEMPLATE_LIST_PAGE';
export const CHECK_VISIT_DO_TEMPLATE_LIST_PAGE_ALLOWED = 'CHECK_VISIT_DO_TEMPLATE_LIST_PAGE_ALLOWED';

// TEMPLATES ROUTE
export const VISIT_ROUTE_TEMPLATE_LIST_PAGE = 'VISIT_ROUTE_TEMPLATE_LIST_PAGE';
export const CHECK_VISIT_ROUTE_TEMPLATE_LIST_PAGE = 'CHECK_VISIT_ROUTE_TEMPLATE_LIST_PAGE';
export const CHECK_VISIT_ROUTE_TEMPLATE_LIST_PAGE_ALLOWED = 'CHECK_VISIT_ROUTE_TEMPLATE_LIST_PAGE_ALLOWED';

// TEMPLATES INSPECTION
export const VISIT_TEMPLATE_INSPECTION_PAGE = 'VISIT_TEMPLATE_INSPECTION_PAGE';
export const CHECK_VISIT_TEMPLATE_INSPECTION_PAGE = 'CHECK_VISIT_TEMPLATE_INSPECTION_PAGE';
export const CHECK_VISIT_TEMPLATE_INSPECTION_PAGE_ALLOWED = 'CHECK_VISIT_TEMPLATE_INSPECTION_PAGE_ALLOWED';

// CONTAINER TYPE
export const VISIT_CONTAINER_TYPE_LIST_PAGE = 'VISIT_CONTAINER_TYPE_LIST_PAGE';
export const CHECK_VISIT_CONTAINER_TYPE_LIST_PAGE = 'CHECK_VISIT_CONTAINER_TYPE_LIST_PAGE';
export const CHECK_VISIT_CONTAINER_TYPE_LIST_PAGE_ALLOWED = 'CHECK_VISIT_CONTAINER_TYPE_LIST_PAGE_ALLOWED';

// CONTAINER TEMPLATE
export const VISIT_CONTAINER_TEMPLATE_LIST_PAGE = 'VISIT_CONTAINER_TEMPLATE_LIST_PAGE';
export const CHECK_VISIT_CONTAINER_TEMPLATE_LIST_PAGE = 'CHECK_VISIT_CONTAINER_TEMPLATE_LIST_PAGE';
export const CHECK_VISIT_CONTAINER_TEMPLATE_LIST_PAGE_ALLOWED = 'CHECK_VISIT_CONTAINER_TEMPLATE_LIST_PAGE_ALLOWED';

// ORDER TEMPLATE
export const VISIT_TEMPLATE_DETAILS_PAGE = 'VISIT_TEMPLATE_DETAILS_PAGE';
export const CHECK_VISIT_TEMPLATE_DETAILS_PAGE = 'CHECK_VISIT_TEMPLATE_DETAILS_PAGE';
export const CHECK_VISIT_TEMPLATE_DETAILS_PAGE_ALLOWED = 'CHECK_VISIT_TEMPLATE_DETAILS_PAGE_ALLOWED';

// LITE ORDER TEMPLATE
export const VISIT_LITE_TEMPLATE_DETAILS_PAGE = 'VISIT_LITE_TEMPLATE_DETAILS_PAGE';
export const CHECK_VISIT_LITE_TEMPLATE_DETAILS_PAGE = 'CHECK_VISIT_LITE_TEMPLATE_DETAILS_PAGE';
export const CHECK_VISIT_LITE_TEMPLATE_DETAILS_PAGE_ALLOWED = 'CHECK_VISIT_LITE_TEMPLATE_DETAILS_PAGE_ALLOWED';

// LOAD PLANNER TEMPLATE
export const VISIT_LOAD_PLANNER_TEMPLATE_LIST_PAGE = 'VISIT_LOAD_PLANNER_TEMPLATE_LIST_PAGE';
export const CHECK_VISIT_LOAD_PLANNER_TEMPLATE_LIST_PAGE = 'CHECK_VISIT_LOAD_PLANNER_TEMPLATE_LIST_PAGE';
export const CHECK_VISIT_LOAD_PLANNER_TEMPLATE_LIST_PAGE_ALLOWED =
  'CHECK_VISIT_LOAD_PLANNER_TEMPLATE_LIST_PAGE_ALLOWED';

// CONTACT BOOK
export const VISIT_CONTACT_BOOK_PAGE = 'VISIT_CONTACT_BOOK_PAGE';
export const CHECK_VISIT_CONTACT_BOOK_PAGE = 'CHECK_VISIT_CONTACT_BOOK_PAGE';
export const CHECK_VISIT_CONTACT_BOOK_PAGE_ALLOWED = 'CHECK_VISIT_CONTACT_BOOK_PAGE_ALLOWED';

// COMPENSATION TEMPLATE
export const VISIT_COMPENSATION_TEMPLATE_LIST_PAGE = 'VISIT_COMPENSATION_TEMPLATE_LIST_PAGE';
export const CHECK_VISIT_COMPENSATION_TEMPLATE_LIST_PAGE = 'CHECK_VISIT_COMPENSATION_TEMPLATE_LIST_PAGE';
export const CHECK_VISIT_COMPENSATION_TEMPLATE_LIST_PAGE_ALLOWED =
  'CHECK_VISIT_COMPENSATION_TEMPLATE_LIST_PAGE_ALLOWED';

// SHARED ACCESSORIAL
export const VISIT_SHARED_ACCESORIALS_PAGE = 'VISIT_SHARED_ACCESORIALS_PAGE';
export const CHECK_VISIT_SHARED_ACCESORIALS_PAGE = 'CHECK_VISIT_SHARED_ACCESORIALS_PAGE';
export const CHECK_VISIT_SHARED_ACCESORIALS_PAGE_ALLOWED = 'CHECK_VISIT_SHARED_ACCESORIALS_PAGE_ALLOWED';

// AVERAGE FUEL PRICE
export const VISIT_AVERAGE_FUEL_PRICE_PAGE = 'VISIT_AVERAGE_FUEL_PRICE_PAGE';
export const CHECK_VISIT_AVERAGE_FUEL_PRICE_PAGE = 'CHECK_VISIT_AVERAGE_FUEL_PRICE_PAGE';
export const CHECK_VISIT_AVERAGE_FUEL_PRICE_PAGE_ALLOWED = 'CHECK_VISIT_AVERAGE_FUEL_PRICE_PAGE_ALLOWED';

// CARRIER CONTRACTS
export const VISIT_CARRIER_CONTRACTS_PAGE = 'VISIT_CARRIER_CONTRACTS_PAGE';
export const CHECK_VISIT_CARRIER_CONTRACTS_PAGE = 'CHECK_VISIT_CARRIER_CONTRACTS_PAGE';
export const CHECK_VISIT_CARRIER_CONTRACTS_PAGE_ALLOWED = 'CHECK_VISIT_CARRIER_CONTRACTS_PAGE_ALLOWED';

// CARRIER CONTRACT DETAILS
export const VISIT_CARRIER_CONTRACT_DETAILS_PAGE = 'VISIT_CARRIER_CONTRACT_DETAILS_PAGE';
export const CHECK_VISIT_CARRIER_CONTRACT_DETAILS_PAGE = 'CHECK_VISIT_CARRIER_CONTRACT_DETAILS_PAGE';
export const CHECK_VISIT_CARRIER_CONTRACT_DETAILS_PAGE_ALLOWED = 'CHECK_VISIT_CARRIER_CONTRACT_DETAILS_PAGE_ALLOWED';

// CUSTOMER CONTRACT DETAILS
export const VISIT_CUSTOMER_CONTRACT_DETAILS_PAGE = 'VISIT_CUSTOMER_CONTRACT_DETAILS_PAGE';
export const CHECK_VISIT_CUSTOMER_CONTRACT_DETAILS_PAGE = 'CHECK_VISIT_CUSTOMER_CONTRACT_DETAILS_PAGE';
export const CHECK_VISIT_CUSTOMER_CONTRACT_DETAILS_PAGE_ALLOWED = 'CHECK_VISIT_CUSTOMER_CONTRACT_DETAILS_PAGE_ALLOWED';

// CUSTOMER CONTRACTS
export const VISIT_CUSTOMER_CONTRACTS_PAGE = 'VISIT_CUSTOMER_CONTRACTS_PAGE';
export const CHECK_VISIT_CUSTOMER_CONTRACTS_PAGE = 'CHECK_VISIT_CUSTOMER_CONTRACTS_PAGE';
export const CHECK_VISIT_CUSTOMER_CONTRACTS_PAGE_ALLOWED = 'CHECK_VISIT_CUSTOMER_CONTRACTS_PAGE_ALLOWED';

// RATING GEO FENCING ZONE
export const VISIT_RATING_GEO_FENCING_ZONE_PAGE = 'VISIT_RATING_GEO_FENCING_ZONE_PAGE';
export const CHECK_VISIT_RATING_GEO_FENCING_ZONE_PAGE = 'CHECK_VISIT_RATING_GEO_FENCING_ZONE_PAGE';
export const CHECK_VISIT_RATING_GEO_FENCING_ZONE_PAGE_ALLOWED = 'CHECK_VISIT_RATING_GEO_FENCING_ZONE_PAGE_ALLOWED';

// REPORT GEO FENCING ZONE
export const VISIT_REPORT_GEO_FENCING_ZONE_PAGE = 'VISIT_REPORT_GEO_FENCING_ZONE_PAGE';
export const CHECK_VISIT_REPORT_GEO_FENCING_ZONE_PAGE = 'CHECK_VISIT_REPORT_GEO_FENCING_ZONE_PAGE';
export const CHECK_VISIT_REPORT_GEO_FENCING_ZONE_PAGE_ALLOWED = 'CHECK_VISIT_REPORT_GEO_FENCING_ZONE_PAGE_ALLOWED';

// DRIVERS CARD
export const VISIT_DRIVERS_CARD_PAGE = 'VISIT_DRIVERS_CARD_PAGE';
export const CHECK_VISIT_DRIVERS_CARD_PAGE = 'CHECK_VISIT_DRIVERS_CARD_PAGE';
export const CHECK_VISIT_DRIVERS_CARD_PAGE_ALLOWED = 'CHECK_VISIT_DRIVERS_CARD_PAGE_ALLOWED';

// CONFIG AUDIT
export const VISIT_CONFIG_AUDIT_PAGE = 'VISIT_CONFIG_AUDIT_PAGE';
export const CHECK_VISIT_CONFIG_AUDIT_PAGE = 'CHECK_VISIT_CONFIG_AUDIT_PAGE';
export const CHECK_VISIT_CONFIG_AUDIT_PAGE_ALLOWED = 'CHECK_VISIT_CONFIG_AUDIT_PAGE_ALLOWED';

// DISPATCH DETAILS LOAD
export const VISIT_DISPATCH_DETAILS_LOAD_PAGE = 'VISIT_DISPATCH_DETAILS_LOAD_PAGE';
export const CHECK_VISIT_DISPATCH_DETAILS_LOAD_PAGE = 'CHECK_VISIT_DISPATCH_DETAILS_LOAD_PAGE';
export const CHECK_VISIT_DISPATCH_DETAILS_LOAD_PAGE_ALLOWED = 'CHECK_VISIT_DISPATCH_DETAILS_LOAD_PAGE_ALLOWED';

// DISPATCH DETAILS ORDER
export const VISIT_DISPATCH_DETAILS_ORDER_PAGE = 'VISIT_DISPATCH_DETAILS_ORDER_PAGE';
export const CHECK_VISIT_DISPATCH_DETAILS_ORDER_PAGE = 'CHECK_VISIT_DISPATCH_DETAILS_ORDER_PAGE';
export const CHECK_VISIT_DISPATCH_DETAILS_ORDER_PAGE_ALLOWED = 'CHECK_VISIT_DISPATCH_DETAILS_ORDER_PAGE_ALLOWED';

// DISPATCH GROUP
export const VISIT_DISPATCH_GROUP_PAGE = 'VISIT_DISPATCH_GROUP_PAGE';
export const CHECK_VISIT_DISPATCH_GROUP_PAGE = 'CHECK_VISIT_DISPATCH_GROUP_PAGE';
export const CHECK_VISIT_DISPATCH_GROUP_PAGE_ALLOWED = 'CHECK_VISIT_DISPATCH_GROUP_PAGE_ALLOWED';

// NORMALIZE CURRENCY LIST
export const VISIT_NORMALIZE_CURRENCY_LIST_PAGE = 'VISIT_NORMALIZE_CURRENCY_LIST_PAGE';
export const CHECK_VISIT_NORMALIZE_CURRENCY_LIST_PAGE = 'CHECK_VISIT_NORMALIZE_CURRENCY_LIST_PAGE';
export const CHECK_VISIT_NORMALIZE_CURRENCY_LIST_PAGE_ALLOWED = 'CHECK_VISIT_NORMALIZE_CURRENCY_LIST_PAGE_ALLOWED';

// COMMISSION ASSIGNMENT
export const VISIT_COMMISSION_ASSIGNMENT_PAGE = 'VISIT_COMMISSION_ASSIGNMENT_PAGE';
export const CHECK_VISIT_COMMISSION_ASSIGNMENT_PAGE = 'CHECK_VISIT_COMMISSION_ASSIGNMENT_PAGE';
export const CHECK_VISIT_COMMISSION_ASSIGNMENT_PAGE_ALLOWED = 'CHECK_VISIT_COMMISSION_ASSIGNMENT_PAGE_ALLOWED';

// CARRIER RATING REPORT LIST
export const VISIT_CARRIER_RATING_REPORT_LIST_PAGE = 'VISIT_CARRIER_RATING_REPORT_LIST_PAGE';
export const CHECK_VISIT_CARRIER_RATING_REPORT_LIST_PAGE = 'CHECK_VISIT_CARRIER_RATING_REPORT_LIST_PAGE';
export const CHECK_VISIT_CARRIER_RATING_REPORT_LIST_PAGE_ALLOWED =
  'CHECK_VISIT_CARRIER_RATING_REPORT_LIST_PAGE_ALLOWED';

// CUSTOMER PORTAL
export const VISIT_CUSTOMER_PORTAL_PAGE = 'VISIT_CUSTOMER_PORTAL_PAGE';
export const CHECK_VISIT_CUSTOMER_PORTAL_PAGE = 'CHECK_VISIT_CUSTOMER_PORTAL_PAGE';
export const CHECK_VISIT_CUSTOMER_PORTAL_PAGE_ALLOWED = 'CHECK_VISIT_CUSTOMER_PORTAL_PAGE_ALLOWED';

// IMPORT REPORT
export const VISIT_IMPORT_REPORT_PAGE = 'VISIT_IMPORT_REPORT_PAGE';
export const CHECK_VISIT_IMPORT_REPORT_PAGE = 'CHECK_VISIT_IMPORT_REPORT_PAGE';
export const CHECK_VISIT_IMPORT_REPORT_PAGE_ALLOWED = 'CHECK_VISIT_IMPORT_REPORT_PAGE_ALLOWED';

// CLO RATE CHARGE REPORT
export const VISIT_CLO_RATE_CHARGE_REPORT_PAGE = 'VISIT_CLO_RATE_CHARGE_REPORT_PAGE';
export const CHECK_VISIT_CLO_RATE_CHARGE_REPORT_PAGE = 'CHECK_VISIT_CLO_RATE_CHARGE_REPORT_PAGE';
export const CHECK_VISIT_CLO_RATE_CHARGE_REPORT_PAGE_ALLOWED = 'CHECK_VISIT_CLO_RATE_CHARGE_REPORT_PAGE_ALLOWED';

// TEL RATE CHARGE REPORT
export const VISIT_TEL_RATE_CHARGE_REPORT_PAGE = 'VISIT_TEL_RATE_CHARGE_REPORT_PAGE';
export const CHECK_VISIT_TEL_RATE_CHARGE_REPORT_PAGE = 'CHECK_VISIT_TEL_RATE_CHARGE_REPORT_PAGE';
export const CHECK_VISIT_TEL_RATE_CHARGE_REPORT_PAGE_ALLOWED = 'CHECK_VISIT_TEL_RATE_CHARGE_REPORT_PAGE_ALLOWED';

// SERVICE VENDOR LIST
export const VISIT_SERVICE_VENDOR_LIST_PAGE = 'VISIT_SERVICE_VENDOR_LIST_PAGE';
export const CHECK_VISIT_SERVICE_VENDOR_LIST_PAGE = 'CHECK_VISIT_SERVICE_VENDOR_LIST_PAGE';
export const CHECK_VISIT_SERVICE_VENDOR_LIST_PAGE_ALLOWED =
  'CHECK_VISIT_SERVICE_VENDOR_LIST_PAGE_ALLOWED';

// SERVICE VENDOR PROFILE
export const VISIT_SERVICE_VENDOR_EDIT_PAGE = 'VISIT_SERVICE_VENDOR_EDIT_PAGE';
export const CHECK_VISIT_SERVICE_VENDOR_EDIT_PAGE = 'CHECK_VISIT_SERVICE_VENDOR_EDIT_PAGE';
export const CHECK_VISIT_SERVICE_VENDOR_EDIT_PAGE_ALLOWED =
  'CHECK_VISIT_SERVICE_VENDOR_EDIT_PAGE_ALLOWED';

// SERVICE VENDOR CONFIG
export const VISIT_CONFIG_SERVICE_VENDOR_PAGE = 'VISIT_CONFIG_SERVICE_VENDOR_PAGE';
export const CHECK_VISIT_CONFIG_SERVICE_VENDOR_PAGE = 'CHECK_VISIT_CONFIG_SERVICE_VENDOR_PAGE';
export const CHECK_VISIT_CONFIG_SERVICE_VENDOR_PAGE_ALLOWED = 'CHECK_VISIT_CONFIG_SERVICE_VENDOR_PAGE_ALLOWED';

// portals
export const VISIT_PORTAL_ACCEPT_PAGE = 'VISIT_PORTAL_ACCEPT_PAGE';
export const VISIT_PORTAL_DECLINE_PAGE = 'VISIT_PORTAL_DECLINE_PAGE';

// RATE SHOP
export const VISIT_RATE_SHOP_PAGE = 'VISIT_RATE_SHOP_PAGE';
export const CHECK_VISIT_RATE_SHOP_PAGE = 'CHECK_VISIT_RATE_SHOP_PAGE';
export const CHECK_VISIT_RATE_SHOP_PAGE_ALLOWED = 'CHECK_VISIT_RATE_SHOP_PAGE_ALLOWED';

// FLEET GENERAL CONFIG
export const VISIT_CONFIG_FLEET_GENERAL_PAGE = 'VISIT_CONFIG_FLEET_GENERAL_PAGE';
export const CHECK_VISIT_CONFIG_FLEET_GENERAL_PAGE = 'CHECK_VISIT_CONFIG_FLEET_GENERAL_PAGE';
export const CHECK_VISIT_CONFIG_FLEET_GENERAL_PAGE_ALLOWED = 'CHECK_VISIT_CONFIG_FLEET_GENERAL_PAGE_ALLOWED';

// FLEET EQUIPMENT SERVICE
export const VISIT_FLEET_EQUIPMENT_SERVICE_LIST_PAGE = 'VISIT_FLEET_EQUIPMENT_SERVICE_LIST_PAGE';
export const CHECK_VISIT_FLEET_EQUIPMENT_SERVICE_LIST_PAGE = 'CHECK_VISIT_FLEET_EQUIPMENT_SERVICE_LIST_PAGE';
export const CHECK_VISIT_FLEET_EQUIPMENT_SERVICE_LIST_PAGE_ALLOWED =
  'CHECK_VISIT_FLEET_EQUIPMENT_SERVICE_LIST_PAGE_ALLOWED';

// FLEET ISSUE
export const VISIT_FLEET_SERVICE_ISSUES_LIST_PAGE = 'VISIT_FLEET_SERVICE_ISSUES_LIST_PAGE';
export const CHECK_VISIT_FLEET_SERVICE_ISSUES_LIST_PAGE = 'CHECK_VISIT_FLEET_SERVICE_ISSUES_LIST_PAGE';
export const CHECK_VISIT_FLEET_SERVICE_ISSUES_LIST_PAGE_ALLOWED = 'CHECK_VISIT_FLEET_SERVICE_ISSUES_LIST_PAGE_ALLOWED';

// FLEET DRIVER PAYROLL ACCESSORIAL
export const VISIT_FLEET_DRIVER_PAYROLL_ACCESSORIAL_LIST_PAGE = 'VISIT_FLEET_DRIVER_PAYROLL_ACCESSORIAL_LIST_PAGE';
export const CHECK_VISIT_FLEET_DRIVER_PAYROLL_ACCESSORIAL_LIST_PAGE =
  'CHECK_VISIT_FLEET_DRIVER_PAYROLL_ACCESSORIAL_LIST_PAGE';
export const CHECK_VISIT_FLEET_DRIVER_PAYROLL_ACCESSORIAL_LIST_PAGE_ALLOWED =
  'CHECK_VISIT_FLEET_DRIVER_PAYROLL_ACCESSORIAL_LIST_PAGE_ALLOWED';

// FLEET VENDOR PAYROLL ACCESSORIAL
export const VISIT_FLEET_VENDOR_PAYROLL_ACCESSORIAL_LIST_PAGE = 'VISIT_FLEET_VENDOR_PAYROLL_ACCESSORIAL_LIST_PAGE';
export const CHECK_VISIT_FLEET_VENDOR_PAYROLL_ACCESSORIAL_LIST_PAGE =
  'CHECK_VISIT_FLEET_VENDOR_PAYROLL_ACCESSORIAL_LIST_PAGE';
export const CHECK_VISIT_FLEET_VENDOR_PAYROLL_ACCESSORIAL_LIST_PAGE_ALLOWED =
  'CHECK_VISIT_FLEET_VENDOR_PAYROLL_ACCESSORIAL_LIST_PAGE_ALLOWED';

// FLEET SHARED COMPONENT
export const VISIT_FLEET_SHARED_COMPONENT_LIST_PAGE = 'VISIT_FLEET_SHARED_COMPONENT_LIST_PAGE';
export const CHECK_VISIT_FLEET_SHARED_COMPONENT_LIST_PAGE = 'CHECK_VISIT_FLEET_SHARED_COMPONENT_LIST_PAGE';
export const CHECK_VISIT_FLEET_SHARED_COMPONENT_LIST_PAGE_ALLOWED =
  'CHECK_VISIT_FLEET_SHARED_COMPONENT_LIST_PAGE_ALLOWED';

// ROUTE TEMPLATE SERVICE
export const VISIT_ROUTE_MASS_CREATE_REPORT_PAGE = 'VISIT_ROUTE_MASS_CREATE_REPORT_PAGE';
export const CHECK_VISIT_ROUTE_MASS_CREATE_REPORT_PAGE = 'CHECK_VISIT_ROUTE_MASS_CREATE_REPORT_PAGE';
export const CHECK_VISIT_ROUTE_MASS_CREATE_REPORT_PAGE_ALLOWED =
  'CHECK_VISIT_ROUTE_MASS_CREATE_REPORT_PAGE_ALLOWED';

// LOS SUBSCRIPTION
export const VISIT_ROUTE_LOS_SUBSCRIPTION_PAGE = 'VISIT_ROUTE_LOS_SUBSCRIPTION_PAGE';
export const CHECK_VISIT_ROUTE_LOS_SUBSCRIPTION_PAGE = 'CHECK_VISIT_ROUTE_LOS_SUBSCRIPTION_PAGE';
export const CHECK_VISIT_ROUTE_LOS_SUBSCRIPTION_PAGE_ALLOWED = 'CHECK_VISIT_ROUTE_LOS_SUBSCRIPTION_PAGE_ALLOWED';

// TASK MANAGEMENT
export const VISIT_TASK_MANAGEMENT_PAGE = 'VISIT_TASK_MANAGEMENT_PAGE';
export const CHECK_VISIT_TASK_MANAGEMENT_PAGE = 'CHECK_VISIT_TASK_MANAGEMENT_PAGE';
export const CHECK_VISIT_TASK_MANAGEMENT_PAGE_ALLOWED = 'CHECK_VISIT_TASK_MANAGEMENT_PAGE_ALLOWED';

// AVAILABLE DRIVER
export const VISIT_ROUTE_AVAILABLE_DRIVER_PAGE = 'VISIT_ROUTE_AVAILABLE_DRIVER_PAGE';
export const CHECK_VISIT_ROUTE_AVAILABLE_DRIVER_PAGE = 'CHECK_VISIT_ROUTE_AVAILABLE_DRIVER_PAGE';
export const CHECK_VISIT_ROUTE_AVAILABLE_DRIVER_PAGE_ALLOWED = 'CHECK_VISIT_ROUTE_AVAILABLE_DRIVER_PAGE_ALLOWED';

// OAUTH POPUP
export const VISIT_ROUTE_OAUTH_POPUP_PAGE = 'VISIT_ROUTE_OAUTH_POPUP_PAGE';

// CLAIM MANAGEMENT
export const VISIT_CLAIM_MANAGEMENT_LIST_PAGE = 'VISIT_CLAIM_MANAGEMENT_LIST_PAGE';
export const CHECK_VISIT_CLAIM_MANAGEMENT_LIST_PAGE = 'CHECK_VISIT_CLAIM_MANAGEMENT_LIST_PAGE';
export const CHECK_VISIT_CLAIM_MANAGEMENT_LIST_PAGE_ALLOWED = 'CHECK_VISIT_CLAIM_MANAGEMENT_LIST_PAGE_ALLOWED';

// CLAIM MANAGEMENT CLAIM DETAILS
export const VISIT_CLAIM_MANAGEMENT_CLAIM_DETAILS_PAGE = 'VISIT_CLAIM_MANAGEMENT_CLAIM_DETAILS_PAGE';
export const CHECK_VISIT_CLAIM_MANAGEMENT_CLAIM_DETAILS_PAGE = 'CHECK_VISIT_CLAIM_MANAGEMENT_CLAIM_DETAILS_PAGE';
export const CHECK_VISIT_CLAIM_MANAGEMENT_CLAIM_DETAILS_PAGE_ALLOWED =
  'CHECK_VISIT_CLAIM_MANAGEMENT_CLAIM_DETAILS_PAGE_ALLOWED';

// IMPORT REPORT
export const VISIT_INSPECTION_REPORT_PAGE = 'VISIT_INSPECTION_REPORT_PAGE';
export const CHECK_VISIT_INSPECTION_REPORT_PAGE = 'CHECK_VISIT_INSPECTION_REPORT_PAGE';
export const CHECK_VISIT_INSPECTION_REPORT_PAGE_ALLOWED = 'CHECK_VISIT_INSPECTION_REPORT_PAGE_ALLOWED';

// WORK ORDER
export const VISIT_WORK_ORDER_LIST_PAGE = 'VISIT_WORK_ORDER_LIST_PAGE';
export const CHECK_VISIT_WORK_ORDER_LIST_PAGE = 'CHECK_VISIT_WORK_ORDER_LIST_PAGE';
export const CHECK_VISIT_WORK_ORDER_LIST_PAGE_ALLOWED = 'CHECK_VISIT_WORK_ORDER_LIST_PAGE_ALLOWED';
